@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100..900&display=swap");

body {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  margin: 0;
  padding: 0;
}

.bg-main {
  background: linear-gradient(#6c40c9, #804fa0);
  color: #fff;
}

.bg-main:hover {
  background: linear-gradient(#804fa0, #6c40c9);
}

.bg-primary {
  background: #141625;
}

.bg-disable {
  background: #dfe1ec;
}

.bg-secondary {
  background: #1f212c;
}

/* width */
::-webkit-scrollbar {
  width: 10px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888 !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

/* width */
.scroll-roadmap::-webkit-scrollbar {
  width: 5px !important;
}

.scroll-roadmap::-webkit-scrollbar-thumb {
  background: #a1a2a2 !important;
}

.scroll-roadmap::-webkit-scrollbar-thumb:hover {
  background: #dedede !important;
}

.scroll-roadmap::-webkit-scrollbar-track {
  background: #dedede !important;
}

.hide-scroll::-webkit-scrollbar {
  width: 0px !important;
}

.hide-scroll-auto::-webkit-scrollbar {
  width: 10px !important;
}

/* Track */
.hide-scroll-auto::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

/* Handle */
.hide-scroll-auto::-webkit-scrollbar-thumb {
  background: #888 !important;
}

/* Handle on hover */
.hide-scroll-auto::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

/* Table Mui */
.MuiDataGrid-panel .MuiDataGrid-filterForm {
  background: #1f212c;
}

.MuiDataGrid-panel .MuiInputLabel-root {
  font-family: "Montserrat", sans-serif;
  color: #d5d7de;
}

.MuiDataGrid-panel .MuiInputLabel-root.Mui-focused {
  color: #d5d7de;
}

.MuiDataGrid-panel .MuiInput-root {
  font-family: "Montserrat", sans-serif;
  color: #d5d7de;
}

.MuiDataGrid-menuList {
  background: #1f212c;
}

.MuiDataGrid-menuList .MuiTypography-root {
  font-family: "Montserrat", sans-serif;
  color: #d5d7de;
}
/* Table Mui */
